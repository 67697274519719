<template>
  <div class="editor">
    <PageBuilderGallery :pageId="pageId"/>
  </div>
</template>

<script>
// @ is an alias to /src
import PageBuilderGallery from '@/components/PageBuilderGallery.vue'

export default {
  name: 'editPage',
  components: {
    PageBuilderGallery
  },
  data() {
    return {
      loading: true,
      pageId: this.$route.params.id
    }
  }
}
</script>

<style scoped lang="scss">

</style>
